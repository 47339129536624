import { graphql, useStaticQuery } from "gatsby"
import React, { useContext, useEffect, useState, useReducer } from "react"
import { FoodCarouselNovinky } from "../components/food-novinky"
import { Hero } from "../components/hero-novemenu"
import { Loader } from "../components/loader"
import { Order2 } from "../components/order-form2"
import { SaleFormModal2 } from "../components/sale-form-modal2"
import SEO from "../components/seo"
import { AffiliateContext } from "../contexts/AffiliateContext"
import { HomepageTabsProvider, homepageTabsReducer, initialState } from '../contexts/HomepageTabsContext';

const ReturnPageLayout = ({ site }) => {
    const [state, dispatch] = useReducer(homepageTabsReducer, initialState)
    const affiliateContext = useContext(AffiliateContext)

    useEffect(() => {
        dispatch({ type: "OPEN_TAB2" })
    }, []);

    useEffect(() => {
        dispatch({ type: "OPEN_TAB2" })
    }, [])

    // useEffect(() => {
    //     window.dataLayer = window.dataLayer || []
    //     window.dataLayer &&
    //     window.dataLayer.push({
    //         pageURL: "/novinky",
    //         testovani: websiteVersion,
    //     })
    // }, [websiteVersion])

    return (
        <>
            <HomepageTabsProvider
                value={{ activeTab: state.activeTab, dispatchAction: dispatch }}
            >
                <SEO title="Return" />
                {/* componetns here */}
                <SaleFormModal2 formName="Akce 2 dny" />
                <Hero />
                <FoodCarouselNovinky />
                <div id="calculator2" />
                <Order2 id="calculator" />
            </HomepageTabsProvider>
        </>
    )
}

const ReturnPage = ({ isAffiliate }) => {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              section
            }
          }
        }
      }
    `
    )

    // for hiding sticky hero btns on mobile when scroll to main form

    const [isVisible, setIsVisible] = useState(true)

    const getOffsetStart = element => {
        const rect = element?.getBoundingClientRect()
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        return rect ? scrollTop + rect.top : scrollTop
    }

    const getOffsetEnd = element => {
        const rect = element?.getBoundingClientRect()
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        return rect ? scrollTop + rect.top + rect.height : scrollTop
    }

    const listenToScroll = () => {
        const hideElem = document.querySelector("#sticky-buttons")
        const elemWhenHide = document.querySelector("#calculator")
        const offsetToHideFromStart = getOffsetStart(elemWhenHide)
        const offsetToHideFromEnd = getOffsetEnd(elemWhenHide)
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop
        if (winScroll > offsetToHideFromStart && winScroll < offsetToHideFromEnd) {
            console.log("winScroll > offsetToHideFromStart")
            isVisible && setIsVisible(false) // to limit setting state only the first time
            hideElem.style.display = "none"
        } else {
            console.log("winScroll < offsetToHideFromStart")
            setIsVisible(true)
            hideElem.style.display = "flex"
        }
    }

    useEffect(() => {
        const isMobile = window.innerWidth < 812
        isMobile && window.addEventListener("scroll", listenToScroll)
        return () =>
            isMobile && window.removeEventListener("scroll", listenToScroll)
    }, [])


    useEffect(() => {
        window.localStorage.removeItem("deliveryPrice") // necessary for order form
    }, [])

    return (
        <>
            {site ? (
                <ReturnPageLayout site={site} isAffiliate={isAffiliate} />
            ) : (
                <Loader />
            )}
        </>
    )
}

export default ReturnPage
