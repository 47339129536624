import "./carousel.css"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import React from "react"
import { useTranslation } from "react-i18next"
import Slider from "react-slick"
import img1 from './images/1.png'
import img2 from './images/2.png'
import img3 from './images/3.png'
import img4 from './images/4.png'

import { useLangContext } from "../../utils/lang"
import { Container } from "../container"
import { FoodCard } from "./food-card"
import styles from "./food-carousel.module.css"

const FoodCardListItem = ({ text, title }) => (
  <li>
    <span className={styles.el}>{text}</span>
    <span className={styles.title}>{title}</span>
  </li>
)

const FoodCardList = ({ children }) => (
  <ul className={styles.foodCardList}>{children}</ul>
)

const FoodCarousel = () => {
  const { t } = useTranslation()
  const slidesData = [
    {
      img: img1,
      title: 'Łosoś teriyaki na makaronie ryżowym, surówka z czerwonej kapusty, marchewki i nutki',
      label: "Obiad",
      param1: "B: 26%",
      param2: "W: 32%",
      param3: "T: 40%",


    },
    {
      img: img2,
      title: 'Kurczak pieczony na tymianku, młode warzywa z ziemniakami',
      label: "Obiad",
      param1: "B: 28%",
      param2: "W: 37%",
      param3: "T: 34%",

    },
    {
      img: img3,
      title: 'Indyk w kremowym sosie z suszonych pomidorów, kolorowy makaron z fasolką, oliwkami i rukolą',
      label: "Obiad",
      param1: "B: 36%",
      param2: "W: 30%",
      param3: "T: 27%",
    },
    {
      img: img4,
      title: 'Hawajskie poke bowl z brązowym ryżem i tofu',
      label: "Podwieczorek",
      param1: "B: 17%",
      param2: "W: 52%",
      param3: "T: 30%",
    },
  ]

  const slides = slidesData.map(item => (
    <div className={styles.carouselItem} key={item.title}>
      <FoodCard label={item.label} img={item.img} title={item.title}>
        <FoodCardList>
          <FoodCardListItem
            text={item.param1}

          />
          <FoodCardListItem
            text={item.param2}

          />
          <FoodCardListItem
            text={item.param3}

          />

        </FoodCardList>
      </FoodCard>
    </div>
  ))

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    // appendDots: dots => {
    //   return (
    //     <div>
    //       <ul>
    //         {dots.map((item, index) => {
    //           return <li key={index}>this is {item.props.children}</li>
    //         })}
    //       </ul>
    //     </div>
    //   )
    // },
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 4,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          // dots: true,
        },
      },
    ],
  }
  return (
    slidesData !== null &&
    slidesData.length > 0 && (
      <div>
        <Slider {...settings}>{slides}</Slider>
      </div>
    )
  )
}

export const FoodCarouselNovinky = () => {
  const { t } = useTranslation()
  const { lang } = useLangContext()

  return (
    <>
      <section className={styles.foodCarouselSection} id="food">
        <Container isWide={true}>
          <h3 className="fancyUnderlineText sectionTitleNew text-center">
            <span>Nowości</span> miesiąca
          </h3>
          <FoodCarousel />
        </Container>
      </section>
    </>
  )
}
